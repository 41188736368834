import { setup } from "mockjs"
const requireContext = require.context("./modules", true, /\.js$/)

const requireContextKeys = requireContext.keys()

requireContextKeys.forEach(item => {
	requireContext(item)
})

setup({
	timeout: "200-600"
})
