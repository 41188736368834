import Types from "./types"
import State from "./state"
import _ from "lodash"

export default (state = State, action) => {
	const { type, payload } = action
	switch (type) {
		case Types.SET_USER_TOKEN:
			state.token = payload
			return _.cloneDeep(state)
		case Types.SET_USER_AUTH:
			state.isAuth = payload
			return _.cloneDeep(state)
		case Types.SET_USER_INFO:
			state.UserInfo = {
				name: payload.name,
				phone: payload.phone
			}
			return _.cloneDeep(state)
		default:
			return state
	}
}
