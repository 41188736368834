import { mock, Random } from "mockjs"

export const GetArticleList = mock(/\/api\/v1\/article/, "get", () => {

	return mock({
		status: 1000,
		message: "获取成功",
		data: {
			"id|1-99999999": 1,
			"title|2": Random.title(),
			"body": Random.cparagraph(1, 8),
			"price|20-10000.1-2": 1,
			"region": Random.city(),
			"sales|20-10000": 100,
			"image": Random.image("400x400", Random.hex(), "#FFF", "Test")
		}
	})
})

