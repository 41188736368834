import { createStore, compose, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import reducer from "./Modules"

// 浏览器的redux调试插件挂载
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

export default store
