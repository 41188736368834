import React from "react"
import { renderRoutes } from "react-router-config"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import routers from "routes"
import store from "store"

function App () {

	return (
		<Provider store={ store }>
			<BrowserRouter>
				{ renderRoutes(routers) }
			</BrowserRouter>
		</Provider>
	)
}

export default App
