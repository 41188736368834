/**
 * @file 全局辅助方法
 * @description 只有全局超过两个地方用到的公用方法才以原子性抽离到该文件，并且不能涉及任何关于react节点内容
 */

/**
 * @description 生成uid
 * @return {string}
 */
export function guid () {
	return "xxxx".replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) || 0
		const v = c === "x" ? r : (r && 0x3) || 0x8
		return v.toString(36)
	})
}

/**
 * @description 随机生成
 * @param min
 * @param max
 * @return {*}
 */
export const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

/**
 * @description 随机生成不同类型的指定长度字符串
 * @param len
 * @param type
 * @param {string} prexfix
 * @return {string}
 */
export const randomString = (len = 64, type = "default", prexfix = "") => {
	const strMap = {
		default: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
		number: "0123456789",
		lowerCase: "abcdefghijklmnopqrstuvwxyz",
		upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
	}
	let result = ""
	const str = strMap[type]
	len -= prexfix.length
	while (len--) {
		result += str[random(0, str.length - 1)]
	}
	return prexfix + result
}

/**
 * @description 数字补零 after before
 * @param num
 * @param n
 * @param type
 * @return {string}
 */
export const prefixInteger = (num = "", n = 0, type = "after") => {
	return type === "before" ? (Array(n).join(0) + num).slice(-n) : (num + Array(n).join(0)).slice(0, n)
}

/**
 * @description URL参数截取 ?&
 * @param {string} variable 需要获取的参数key
 * @param {string} url 需要解析的链接
 */
export function getQueryVariable (variable, url = window.location.href) {
	try {
		const query = url.split("?")[1] || ""
		const vars = query.split("&").reduce((q, i) => {
			const current = i.indexOf("=")
			q[i.slice(0, current)] = i.slice(current + 1) || null
			return q
		}, {})
		return variable ? vars[variable] : vars
	} catch (e) {
		console.log("e", e)
	}
	return false
}
