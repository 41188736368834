import { mock, Random } from "mockjs"
import { getQueryVariable } from "../../src/utils/helper"

export const GetProductList = mock(/\/api\/v1\/products/, "get", (req) => {

	// 解析第几页
	const page = +(getQueryVariable("page", req.url) || 1)
	const pageSize = +(getQueryVariable("page_size", req.url) || 20)

	return mock({
		status: 1000,
		message: "获取成功",
		data: {
			list: Array.from({ length: 200 }, () => ({
				"id|1-99999999": 1,
				"name|2": Random.title(),
				"price|20-10000.1-2": 1,
				"region": Random.city(),
				"sales|20-10000": 100,
				"image": Random.image("400x400", Random.hex(), "#FFF", "Test")
			})),
			page,
			page_size: pageSize

		}
	})
})

