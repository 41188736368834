/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react"
import { MenuLayoutStyle } from "./style"
import { renderRoutes } from "react-router-config"
import { Layout, Menu } from "antd"
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined
} from "@ant-design/icons"

const { Header, Sider, Content } = Layout

function MenuLayout ({ route, history, location }) {

	const [ collapsed, setCollapsed ] = useState(false)

	const MenuList = useMemo(() => {
		const { routes = [] } = route
		return routes.map(r => {
			return (
				<Menu.Item key={ r.path }>
					<div onClick={ () => history.push(r.path) }>{ r.name }</div>
				</Menu.Item>
			)
		})
	}, [route])

	return (
		<MenuLayoutStyle>
			<Layout>
				<Sider trigger={ null } collapsible collapsed={ collapsed }>
					<div className="logo"/>
					<Menu theme="dark" mode="inline" defaultSelectedKeys={ [location.pathname] }>
						{ MenuList }
					</Menu>
				</Sider>
				<Layout className="site-layout">
					<Header className="site-header-background" style={ { padding: 0 } }>
						{ React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
							className: "trigger",
							onClick: () => setCollapsed(v => !v)
						}) }
					</Header>
					<Content className="site-layout-background">
						{ renderRoutes(route.routes) }
					</Content>
				</Layout>
			</Layout>
		</MenuLayoutStyle>
	)
}

export default MenuLayout
