/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from "react"
import MenuLayout from "../layouts/MenuLayout"

const ErrorComponent = () => {

	return (
		<div>页面加载失败</div>
	)
}

const GetView = Component => props => (
	<Suspense fallback={ <ErrorComponent/> }>
		<Component { ...props } />
	</Suspense>
)

const DemoA = lazy(() => import("../views/DemoA"))
const DemoB = lazy(() => import("../views/DemoB"))
const DemoC = lazy(() => import("../views/DemoC"))
const DemoD = lazy(() => import("../views/DemoD"))

export default [
	{
		component: MenuLayout,
		path: "/",
		routes: [
			{
				path: "/demo/a",
				name: "事例A",
				component: GetView(DemoA)
			}, {
				path: "/demo/b",
				name: "事例B",
				component: GetView(DemoB)
			}, {
				path: "/demo/c",
				name: "事例C",
				component: GetView(DemoC)
			}, {
				path: "/demo/d",
				name: "事例D",
				component: GetView(DemoD)
			}
		]
	}
]
