import React from "react"
import ReactDOM from "react-dom"
import App from "./views/App"
import "./assets/style/base.css"
import reportWebVitals from "./reportWebVitals"

import "../mock"

ReactDOM.render((
	<React.StrictMode>
		<App />
	</React.StrictMode>
), document.getElementById("root"))

reportWebVitals()
